
import fondo from '../../../images/fondo_home.jpg';

export default function Home() {
    return (
        <img
            src={fondo}
            alt="fondo"
            className="h-full w-full object-center"
        />
    );
}
