import { useSucursalContext } from "../../../contexts/SucursalContext";

export default function PerfilSucursal({ setIsModalOpenPerfil }) {

    const { getSucursal } = useSucursalContext()
    const data = getSucursal()

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white  p-4 rounded shadow-lg shadow-black border border-black">

                <div className="px-5 py-2 lg:px-10 lg:py-5">

                    <h2 className="text-xl text-center font-bold mb-1">{data?.razonSocial}</h2>
                    <hr className='mb-4 border-black border-1' />

                    <p><strong>Localidad:</strong> {data?.nombreLocalidad}</p>
                    <p> <strong>CUIT:</strong> {data?.cuit}</p>
                    <p><strong>Domicilio:</strong>  {data?.domicilio}</p>
                    <p><strong>Teléfono:</strong> {data?.telefono}</p>

                </div>
                <div className='flex justify-center items-center'>

                    <button onClick={() => setIsModalOpenPerfil(false)} className='bg-red-500 text-center border border-black text-white py-0.5 px-4 font-semibold rounded-sm shadow-md shadow-black '>CERRAR</button>
                </div>
            </div>
        </div>
    );
}
