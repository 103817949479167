import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../../../images/logo-integ.png'



export default function LoginSucursal() {
    const navigate = useNavigate();

    const [password, setPassword] = useState('')
    const [errorMensaje, setErrorMensaje] = useState('')
    const [cargando, setCargando] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    const [sucursal, setSucursal] = useState('');

    const sucursales = [
        { nombre: 'Integral Sur', value: 'sur' },
        { nombre: 'Melo', value: 'mel' },
        { nombre: 'Laboulaye', value: 'lbye' },
        { nombre: 'Serrano', value: 'serr' },
        { nombre: 'Buchardo', value: 'buch' },
        { nombre: 'Villa Valeria', value: 'val' },
        { nombre: 'Pueblo Italiano', value: 'pue' },
    ];

    const handleSucursalChange = (e) => {
        setSucursal(e.target.value);
    };

    const handlePasswordChange = (e) => {
        const passwordIngresado = e.target.value.trim()
        setPassword(passwordIngresado)
        setErrorMensaje('')
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleLogin = async (e) => {
        e.preventDefault();

        setCargando(true); // Comenzar la carga

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login/sucursal/sur`, { password })

            if (response.status === 200) {
                const { token } = response.data
                localStorage.setItem('auth_sucursal', token);
                navigate('/layoutSucursal');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMensaje(error.response.data.message);
            } else {
                setErrorMensaje('Error al iniciar sesión. Por favor, inténtalo de nuevo.');
            }
        } finally {
            setCargando(false); // Terminar la carga
        }
    }

    const regresarPaginaPrincipal = () => {
        navigate('/')
    }

    return (
        <div className="bg-white h-screen overflow-hidden flex items-center justify-center  " >
            <div className="bg-black lg:w-4/12 md:6/12 lg:h-96  w-10/12 shadow-lg shadow-black rounded-xl  " >
                <div className="border-2 border-black bg-black absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full p-1 lg:p-0.5 ">
                    <img src={logo} alt="logo" className='lg:w-32 lg:h-32 w-24 h-32 ' />
                </div>
                <form onSubmit={handleLogin} className="p-12 md:p-24 lg:p-20 mt-10  lg:mt-14 ">

                    <div className="mb-6 md:mb-8 lg:mb-2 md:-mt-6 lg:-mt-6 ">
                        <select
                            onChange={handleSucursalChange}
                            value={sucursal}
                            className="bg-white rounded-md  py-1 w-full cursor-pointer font-semibold "
                        >
                            <option value="" className='font-semibold text-center '>SELECCIONE SUCURSAL</option>
                            {sucursales.map((suc) => (
                                <option className='text-center font-semibold ' key={suc.value} value={suc.value}>{suc.nombre}</option>
                            ))}
                        </select>
                    </div>

                 
                    <div className="flex  items-center text-lg mb-6 md:mb-6 lg:mb-4 relative">
                        <input
                            onChange={handlePasswordChange}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete='off'
                            className="bg-white rounded-md pl-2 py-1  lg:py-1  md:py-2 focus:outline-none w-full"
                            placeholder="Ingrese Contraseña..."
                        />
                        <button
                            type="button"
                            onClick={toggleShowPassword}
                            className="absolute right-2 top-1"
                        >
                            {showPassword ? '👁' : '👁‍🗨'}
                        </button>

                    </div>

                    {errorMensaje && <p className="text-white text-center mb-5 -mt-2 -lg:mt-4">{errorMensaje}</p>}

                    <button
                        type='submit'
                        disabled={cargando}
                        className={`bg-green-700 hover:bg-green-800 font-medium rounded-md  p-2 md:p-4 lg:p-2 text-white uppercase w-full transition-transform hover:scale-95 ${cargando ? 'cursor-not-allowed' : ''}`}>
                        {cargando ? 'CARGANDO...' : 'INGRESAR'}
                    </button>
                    <button type='button' onClick={regresarPaginaPrincipal} className="bg-red-600 hover:bg-red-700 font-medium rounded-md mt-2 p-2 md:p-4 lg:p-2  text-white uppercase w-full transition-transform hover:scale-95">VOLVER</button>
                </form>
            </div>
        </div>
    )
}
