import { useClienteContext } from '../../../contexts/ClienteContext';
import { useEmpresaContext } from '../../../contexts/EmpresaContext';
import impresora from '../../../images/impresora.png'
import { generarPdfDetalleStockVentaAnticipada } from '../../../services/generarPdfDetalleStockVentaAnticipada';


export default function DetalleStockVentaAnticipada({ detalleMovStock, onClose, movStockVtaAnticipada }) {


    const { getCliente } = useClienteContext()
    const data = getCliente()
    const { getEmpresa } = useEmpresaContext()
    const empresa = getEmpresa()

    const handleImprimirClick = () => {
        generarPdfDetalleStockVentaAnticipada(detalleMovStock, data, empresa)
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-60" onClick={onClose}></div>
            <div className="relative bg-white p-6 z-50 border border-black w-full max-w-5xl max-h-5xl overflow-hidden">

                <h2 className="text-xl text-center font-semibold  -mt-4">DETALLE STOCK</h2>
                <p className="text-center font-semibold  text-green-500">{movStockVtaAnticipada?.Codarticulo} - {movStockVtaAnticipada?.Descarticulo} </p>

                <div className='flex justify-center items-center mb-0.5 '>
                    <button onClick={handleImprimirClick} title='Imprimir' disabled={!detalleMovStock.length > 0} className={` ${!detalleMovStock.length > 0 && 'opacity-50 cursor-not-allowed'}`}>
                        <img src={impresora} alt="impresora" className='lg:w-8 w-12 lg:h-6 h-8 '/>
                    </button>
                </div>

                <div className="overflow-auto max-h-64 ">
                    <table className="min-w-full border-collapse ">
                        <thead className="bg-black text-white sticky top-0">
                            <tr className="">
                                <th className="p-0.5  lg:px-0 px-8 text-sm  font-semibold  lg:w-10 border border-white">Fecha</th>
                                <th className="p-0.5  lg:px-0 px-14 text-sm font-semibold border  lg:w-72 border-white">Comentario</th>
                                <th className="p-0.5 lg:px-0 px-10  text-sm font-semibold  lg:w-44 border border-white">N°Comprobante</th>
                                <th className="p-0.5  lg:px-0 px-4  text-sm font-semibold border  lg:w-2 border-white">Tipo</th>
                                <th className="p-0.5  lg:px-0 px-7  text-sm font-semibold border  lg:w-20 border-white">Entrada</th>
                                <th className="p-0.5  lg:px-0 px-7  text-sm font-semibold border  lg:w-20 border-white">Salida</th>
                                <th className="p-0.5  lg:px-0 px-7   text-sm font-semibold border  lg:w-20 border-white">Saldo</th>
                            </tr>
                        </thead>
                        <tbody>

                            {detalleMovStock ? (
                                (() => {
                                    let saldoInicial = 0;
                                    return detalleMovStock?.map((detalle, index) => {
                                        if (detalle?.Entsal == 'E') {
                                            saldoInicial += detalle?.Entrada;
                                        } else if (detalle?.Entsal == 'S') {
                                            saldoInicial -= detalle?.Salida;
                                        }

                                        return (
                                            <tr key={index} className="hover:gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300">
                                                <td className="text-sm text-center border border-gray-500">{detalle?.Fecha}</td>
                                                <td className="pl-0.5 text-sm text-left border border-gray-500">{detalle?.Comentario}</td>
                                                <td className="px-0.5 pr-1 text-sm text-center border border-gray-500">{detalle?.Nrocomprobante}</td>
                                                <td className="px-0.5 text-sm text-center border border-gray-500">{detalle?.Entsal}</td>
                                                <td className="px-0.5 text-sm text-right border border-gray-500">{detalle?.Entsal == 'E' ? detalle?.Entrada.toFixed(3) : ''}</td>
                                                <td className="px-0.5 text-sm text-right border border-gray-500">{detalle?.Entsal == 'S' ? detalle?.Salida.toFixed(3) : ''}</td>
                                                <td className="px-0.5 pr-1 text-sm text-right border border-gray-500">{saldoInicial?.toFixed(3)}</td>
                                            </tr>
                                        );
                                    });
                                })()
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-red-500 font-bold text-xl text-center py-4">NO SE ENCONTRARON DETALLES</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>

                <div className="flex justify-end items-center">
                    <p className="font-bold lg:pr-12 pr-16 ">{movStockVtaAnticipada?.Entrada.toFixed(3)}</p>
                    <p className="font-bold lg:pr-11 pr-14">{movStockVtaAnticipada?.Salida.toFixed(3)}</p>
                    <p className="font-bold lg:pr-9 pr-10" >{movStockVtaAnticipada?.Saldo.toFixed(3)}</p>
                </div>
                <div className="flex justify-center items-center mt-0.5 -mb-4">
                    <button
                        className="px-4 py-0.5 w-60 border border-black text-center bg-red-600 text-white font-semibold hover:bg-red-700"
                        onClick={onClose}
                    >
                        CERRAR
                    </button>
                </div>
            </div>
        </div>
    )
}
