import { useEffect, useRef, useState } from 'react';
import { useClienteContext } from '../../../../contexts/ClienteContext';
import { useEmpresaContext } from '../../../../contexts/EmpresaContext';
import axios from 'axios';
import BodyPendienteFacturar from './BodyPendienteFacturar';
import CabeceraPendienteFacturar from './CabeceraPendienteFacturar';
import { generarPdfRemitosPendientesFacturar } from '../../../../services/generarPdfRemitosPendientesFacturar';
import flechaArriba from '../../../../images/flecha-arriba.png'
import flechaAbajo from '../../../../images/flecha-abajo.png'
import impresora from '../../../../images/impresora.png'
import imagenfondo from '../../../../images/logo-sur.jpg'
import imagenFondoIntegral from '../../../../images/logo-nuevo-srl.jpg'
import gifCargando from '../../../../images/cargando.gif'




export default function TablaPendienteFacturar() {

    const sucursal = localStorage.getItem('sucursal')
    // Determina la imagen de fondo según la sucursal
    const fondo = sucursal === 'sur' ? imagenfondo : imagenFondoIntegral;


    const { getCliente } = useClienteContext()
    const data = getCliente()
    const codigo = data.codigoCliente
    const { getEmpresa } = useEmpresaContext()
    const empresa = getEmpresa()

    const [movPendientesFacturar, setMovPendientesFacturar] = useState([])
    // EACA FILTRO PARA OBTENER LOS REMITOS CON GENFACTURA == 1 
    const remitosPendientesFacturar = movPendientesFacturar?.filter(rem => rem?.GenfacturaRemito == '1')
    const [mensajeError, setMensajeError] = useState('')
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    // ESTADO DE CARGA PARA MOSTRAR EL MENSAJE DE CARGANDO
    const [loading, setLoading] = useState(false)

    const tableContainerRef = useRef(null);


    useEffect(() => {

        fetchData()

    }, [codigo]);

    useEffect(() => {
        if (tableContainerRef.current) {
            const { scrollHeight, clientHeight } = tableContainerRef.current;
            setShowScrollButtons(scrollHeight > clientHeight);
            tableContainerRef.current.scrollTop = scrollHeight;
        }
    }, [movPendientesFacturar]);

    const fetchData = async () => {

        if (!codigo || !sucursal) {
            setMensajeError('Código de cliente o sucursal no definido');
            return;
        }

        setLoading(true); // Inicia la carga
        setMensajeError(''); // Resetea el mensaje de error

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/movstock/${sucursal}/pendientefacturar/${codigo}`)

            setMovPendientesFacturar(response?.data)
            setMensajeError('')

        } catch (error) {
            setMensajeError(error.response?.data?.message)
            console.log(error)

        } finally {
            setLoading(false)
        }
    }

    const handleImprimirClick = () => {
        generarPdfRemitosPendientesFacturar(remitosPendientesFacturar, data, empresa);
    };

    const scrollToTop = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
    };

    const scrollToBottom = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
        }
    };


    return (
        <>
            <div className='-p-4 -mb-10 flex justify-center items-center  border border-black ' style={{ background: '#ffff00' }}>
                <p className='font-bold'>REMITOS PENDIENTES DE FACTURAR</p>
            </div>

            <div className='mt-10  -p-4 -mb-12 flex justify-center items-center  border border-black' style={{ background: '#ffff00' }}>
                <p className='font-semibold text-sm'>Última Actualización: {new Date(empresa?.updatedAt)?.toLocaleString('es-ES', { hour12: false })}</p>
            </div>

            <div className="flex-shrink-0 flex justify-center items-center bg-gwhite mt-1 lg:mt-1.5 -mb-0.5 ">
                <div className='flex justify-center items-center mb-0.5 lg:mb-0 mt-12 p-1 lg:p-1 lg:mt-10 '>
                    <button title='Imprimir' onClick={handleImprimirClick} disabled={!movPendientesFacturar.length > 0} className={`${!movPendientesFacturar.length > 0 && 'opacity-50 cursor-not-allowed'}`}>
                        <img src={impresora} alt="impresora" className='ml-2 lg:w-9 w-12 lg:h-7 h-9' />
                    </button>
                </div>
            </div>

            <div className="flex-grow overflow-auto">
                <div className="w-full overflow-auto  h-full relative  bg-center bg-no-repeat  " ref={tableContainerRef} style={{ backgroundImage: `url(${fondo})` }} >

                    <table className="w-full border">
                        <CabeceraPendienteFacturar />
                        <BodyPendienteFacturar remitosPendientesFacturar={remitosPendientesFacturar} empresa={empresa} />
                    </table>

                    {
                        loading && <div className='flex justify-center items-center mt-6'>
                            <img src={gifCargando} alt="gif cargando" />
                        </div>
                    }

                    {
                        !loading && mensajeError && <div className='bg-red-500  border-2 border-black'><p className='text-center text-white font-bold  text-xl'>{mensajeError}</p></div>
                    }


                    {showScrollButtons && (
                        <>
                            <button
                                onClick={scrollToBottom}
                                className="fixed bottom-1 lg:right-6 right-1 bg-transparent p-0 m-0 border-none cursor-pointer"
                                aria-label="Desplazar hacia abajo">
                                <img src={flechaAbajo} alt="Desplazar hacia abajo" className="lg:w-7 lg:h-7 w-10 h-10 transition-transform hover:scale-95" />
                            </button>
                            <button
                                onClick={scrollToTop}
                                className="fixed bottom-1 lg:right-16 bg-transparent p-0 m-0 border-none cursor-pointer"
                                aria-label="Desplazar hacia arriba">
                                <img src={flechaArriba} alt="Desplazar hacia arriba" className="lg:w-7 lg:h-7 w-10 h-10 transition-transform hover:scale-95" />
                            </button>
                        </>
                    )}
                </div>
            </div>

            <div className='bg-black lg:p-2 p-3.5'>
                <p className='text-center text-black font-semibold '>PENDIENTES DE FACTURAR</p>

            </div>

        </>
    )
}
