import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../images/logo-integ.png'

export default function Navbar({ logout, setIsModalOpenPerfil }) {

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [opcionDolares, setOpcionDolares] = useState('')
  const [opcionPesificado, setOpcionPesificado] = useState('')
  const [opcionPesos, setOpcionPesos] = useState('')
  const [opcionStock, setOpcionStock] = useState('')

  const toggleMenu = () => {
    setOpen(!open);
  };

  const openPerfilCloseMenu = () => {
    setOpen(!open)
    setIsModalOpenPerfil(true)
  }

  const handleSelectChange = (event, tipo) => {
    setOpen(!open)
    const selected = event.target.value;
    setOpcionDolares(tipo === 'dolares' ? selected : '');
    setOpcionPesificado(tipo === 'pesificado' ? selected : '');
    setOpcionPesos(tipo === 'pesos' ? selected : '');
    setOpcionStock(tipo === 'stock' ? selected : '');

    const ruta = tipo === 'dolares' ? 'tablaMovVentasDolares'
      : tipo === 'pesificado' ? 'tablaMovVentasPesificados'
        : tipo === 'pesos' ? 'tablaMovVentasPesos'
          : 'tablaStockVentaAnticipada'
    // navigate(`/layoutCliente/${ruta}?opcion=${selected}`);
    navigate(`/layoutCliente/${ruta}?opcion=${selected}`);

  };


  return (
    <nav className="bg-black px-2 lg:px-2 py-3 lg:py-1">

      <div className="flex items-center justify-between container mx-auto">

        <div className=''>
          <a href="/layoutCliente/HomeCliente" className="flex items-center transition-transform hover:scale-95">
            <img src={logo} alt="logo" className="w-9 h-9 lg:w-8 lg:h-8" />
          </a>
        </div>

        <div className="lg:hidden">
          <button className="text-white" onClick={toggleMenu}>
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              viewBox='0 0 24 24'
              className="w-8 h-8"
            >
              <path  d={`${open == true ? 'M4 6h16M4 12h16m-7 6h7' : 'M4 6h16M4 12h16M4 18h16' } `}></path>
            </svg>
          </button>
        </div>

        <ul className='hidden lg:flex  space-x-5 '>
          <li className='text-xs'>
            <select
              name="historico-dolares"
              id="historico-dolares"
              onChange={(e) => handleSelectChange(e, 'dolares')}
              className="block rounded-sm text-white  md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
              aria-current="page"
              value={opcionDolares}
            >
              <option className='font-bold  text-center' value="" disabled>
                Histórico en Dólares
              </option>
              <option className='font-bold   text-center' value="FechaFormateada" >Por Fecha de Factura</option>
              <option className='font-bold   text-center' value="FechavtoFormateada" >Por Fecha de Vencimiento</option>
            </select>
          </li>

          <li className='text-xs'>
            <select
              name="historico-pesificado"
              id="historico-pesificado"
              onChange={(e) => handleSelectChange(e, 'pesificado')}
              className="block rounded-sm text-white md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
              aria-current="page"
              value={opcionPesificado}
            >
              <option className='font-bold text-center' value="" disabled>
                Histórico Pesificados
              </option>
              <option className='font-bold text-center' value="FechaFormateada">Por Fecha de Factura</option>
              <option className='font-bold text-center' value="FechavtoFormateada">Por Fecha de Vencimiento</option>
            </select>
          </li>

          <li className='text-xs'>
            <select
              name="historico-pesos"
              id="historico-pesos"
              onChange={(e) => handleSelectChange(e, 'pesos')}
              className="block rounded-sm text-white md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
              aria-current="page"
              value={opcionPesos}
            >
              <option className='font-bold text-center' value="" disabled>
                Histórico en Pesos
              </option>
              <option className='font-bold text-center' value="FechaFormateada">Por Fecha de Factura</option>
              <option className='font-bold text-center' value="FechavtoFormateada">Por Fecha de Vencimiento</option>
            </select>
          </li>

          <li className='text-xs'>
            <a
              href="/layoutCliente/tablaRemitos"
              className="block    rounded-sm text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
            >
              Remitos
            </a>
          </li>
          <li className='text-xs'>
            <a
              href="/layoutCliente/tablaPendienteFacturar"
              className="block    rounded-sm text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
            >
              Pendiente de Facturar
            </a>
          </li>
          <li className='text-xs' >
            <select
              name="stock"
              id="stock"
              onChange={(e) => handleSelectChange(e, 'stock')}
              className="block rounded-sm text-white md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
              aria-current="page"
              value={opcionStock}
            >
              <option className='font-bold text-center' value="" disabled>
                Stock
              </option>
              <option className='font-bold text-center' value="ventaAnticipada">Venta Anticipada</option>
              <option className='font-bold text-center' value="total">Stock Total</option>
            </select>
          </li>
          <li className='text-xs'>
            <button
              onClick={() => setIsModalOpenPerfil(true)}
              type='button'
              className="block    rounded-sm text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
            >
              Perfil
            </button>
          </li>
        </ul>

        <div className='hidden lg:flex bg-red-500 rounded-sm transition-transform hover:scale-95'>
          <button
            onClick={logout}
            className=' text-xs  text-white p-1 px-2 font-semibold'>
            Cerrar Sesión
          </button>
        </div>

      </div>



      {/* Mobile menu */}
      {open ? (
        <div className='-ml-2  bg-black w-full lg:w-auto  h-full lg:h-auto fixed z-10'>
          <ul className="flex-col  lg:hidden mt-6 mb-4 pl-3">
            <li className='text-md mb-6'>
              <select
                name="historico-dolares"
                id="historico-dolares"
                onChange={(e) => handleSelectChange(e, 'dolares')}
                className="block  rounded-md text-white  md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
                aria-current="page"
                value={opcionDolares}
              >
                <option className='font-bold  text-left' value="" disabled>
                  Histórico en Dólares
                </option>
                <option className='font-bold   text-center' value="FechaFormateada" >Por Fecha de Factura</option>
                <option className='font-bold   text-center' value="FechavtoFormateada" >Por Fecha de Vencimiento</option>
              </select>



            </li>

            <li className='text-md mb-6'>
              <select
                name="historico-pesificado"
                id="historico-pesificado"
                onChange={(e) => handleSelectChange(e, 'pesificado')}
                className="block rounded-md text-white md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
                aria-current="page"
                value={opcionPesificado}
              >
                <option className='font-bold text-left' value="" disabled>
                  Histórico Pesificados
                </option>
                <option className='font-bold text-center' value="FechaFormateada">Por Fecha de Factura</option>
                <option className='font-bold text-center' value="FechavtoFormateada">Por Fecha de Vencimiento</option>
              </select>

            </li>

            <li className='text-md mb-6'>
              <select
                name="historico-pesos"
                id="historico-pesos"
                onChange={(e) => handleSelectChange(e, 'pesos')}
                className="block rounded-md text-white md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
                aria-current="page"
                value={opcionPesos}
              >
                <option className='font-bold text-left' value="" disabled>
                  Histórico en Pesos
                </option>
                <option className='font-bold text-center' value="FechaFormateada">Por Fecha de Factura</option>
                <option className='font-bold text-center' value="FechavtoFormateada">Por Fecha de Vencimiento</option>
              </select>

            </li>

            <li className='text-md mb-6'>
              <a
                href="/layoutCliente/tablaRemitos"
                className="block    rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
              >
                Remitos
              </a>
            </li>

            <li className='text-md mb-6'>
              <a
                href="/layoutCliente/tablaPendienteFacturar"
                className="block    rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
              >
                Pendiente de Facturar
              </a>
            </li>

            <li className='text-md mb-6' >
              <select
                name="stock"
                id="stock"
                onChange={(e) => handleSelectChange(e, 'stock')}
                className="block rounded-md text-white md:text-gray cursor-pointer bg-black lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-bg-gray-700 md:hover:bg-bg-gray-700"
                aria-current="page"
                value={opcionStock}
              >
                <option className='font-bold text-left' value="" disabled>
                  Stock
                </option>
                <option className='font-bold text-center' value="ventaAnticipada">Venta Anticipada</option>
                <option className='font-bold text-center' value="total">Stock Total</option>
              </select>
            </li>

            <li className='text-md mb-6'>
              <button
                onClick={openPerfilCloseMenu}
                type='button'
                className="block    rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
              >
                Perfil
              </button>
            </li>

            <li className='mt-10 bg-red-500 rounded-sm w-36 text-center'>
              <button
                onClick={logout}
                className='text-white font-semibold p-1'>
                Cerrar Sesión
              </button>
            </li>
          </ul>
        </div>

      ) : null}

    </nav>


  );
}