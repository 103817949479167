import { useState } from 'react';
import logo from '../../../images/logo-editado.jpg';


export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    // <nav className="bg-gradient-to-r from-black to-gray-800 p-4 lg:p-1 fixed w-full ">
    <nav className="bg-white p-4 lg:p-1 fixed w-full shadow shadow-black">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <a href="/" className="flex items-center">
          <img src={logo} alt="logo" className="lg:w-40 w-52" />
        </a>

        {/* Mobile Menu Toggle */}
        <button
          type="button"
          className="text-white md:hidden focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="h-9 w-9"
            fill="none"
            viewBox="0 0 24 24"
            stroke="black"
            strokeWidth="3"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d={`${isOpen == true ? 'M4 6h16M4 12h16m-7 6h7' : 'M4 6h16M4 12h16M4 18h16'} `} />
          </svg>
        </button>


        {/* Navigation Links */}
        <div
          className={`absolute  top-full left-0 right-0  md:static lg:static  bg-white ${isOpen ? 'block' : 'hidden'} md:flex md:items-center md:w-auto lg:flex lg:items-center lg:w-auto`}
        >
          <ul className="flex flex-col items-center md:flex-row md:space-x-8 lg:flex-row lg:space-x-8">
            <li className='w-full text-center'>
              <a
                href="/"
                className="block py-6 px-6 lg:py-2 lg:px-3 text-black font-medium lg:font-medium  transition-transform hover:scale-125 lg:hover:text rounded-md text-lg lg:text-sm "
              >
                Inicio
              </a>
            </li>

            <li className='w-full text-center'>
              <a
                href="#"
                className="block py-6 px-6 lg:py-2 lg:px-3 text-black font-medium lg:font-medium  transition-transform hover:scale-125 lg:hover:text rounded-md text-lg lg:text-sm "
              >
                Servicios
              </a>
            </li>
            <li className='w-full text-center'>
              <a
                href="#"
                className="block py-6 px-6 lg:py-2 lg:px-3 text-black font-medium lg:font-medium  transition-transform hover:scale-125 lg:hover:text rounded-md text-lg lg:text-sm "
              >
                Contacto
              </a>
            </li>
            <li className='w-full text-center'>
              <a
                href="/login"
                className="block py-6 px-6 lg:py-2 lg:px-3 text-black font-medium lg:font-medium  transition-transform hover:scale-125 lg:hover:text rounded-md text-lg lg:text-sm "
              >
                Clientes
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
