import { createContext, useContext, useState } from 'react'

// Creaer el contexto
const ClienteContext = createContext();

// Proveedor del contexto
export const ClienteProvider = ({ children }) => {
    const [cliente, setCliente] = useState('')

    // Funcion para guardar los datos del cliente
    const addCliente = (data) => {
        setCliente(data)
    }

    // Funcion para obtener los datos del cliente guardado
    const getCliente = () => {
        return cliente
    }

    // Se proporcionan las funciones
    const contextValue = {
        addCliente,
        getCliente
    }

    return (
        <ClienteContext.Provider value={contextValue}>
            {children}
        </ClienteContext.Provider>
    )
}

// Funcion de utilidad para acceder al contexto
export const useClienteContext = () => {
    return useContext(ClienteContext)
}