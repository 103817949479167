import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const generarPdfStockVentaAnticipada = (filteredData, data, empresa) => {
    const doc = new jsPDF();

    // Datos de la Empresa para cabecera
    const razonsocial = `${empresa?.Razonsocial}`;
    const direccion = `${empresa?.Domicilio} * ${empresa?.NombreLocalidad} * ${empresa?.Telefono}`;
    const email = `${empresa?.Dircorreo}`;

    // Obtener la fecha actual con formato localizado
    const formato = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fecha = `Fecha: ${new Date().toLocaleDateString('es-ES', formato)}`;

    // Configurar posición de la cabecera
    const posicionEmpresa = 10;
    const posicionDireccionEmail = 15;
    const posicionFecha = 24;
    const posicionTitulo = 24;

    // Agregar los datos de la cabecera al PDF con estilo personalizado
    doc.setFontSize(18); // Tamaño de fuente más grande para el nombre de la empresa
    doc.setFont('bold'); // Establecer la fuente en negrita
    doc.text(razonsocial, 10, posicionEmpresa);
    doc.setFont('normal'); // Restaurar la fuente a la normal después del texto en negrita

    doc.setFontSize(10); // Tamaño de fuente normal para otros datos
    // Colocar dirección y correo electrónico en la misma línea
    doc.text(direccion + ' * ' + email, 10, posicionDireccionEmail);
    doc.setFontSize(12)
    doc.text(fecha, 10, posicionFecha);

    // Establecer un título con estilo personalizado y centrado
    const titulo = 'Resumen Stock Venta Anticipada';
    doc.setFontSize(18); // Tamaño de fuente más grande para el título
    doc.setFont('bold'); // Establecer la fuente en negrita
    doc.setTextColor(0, 0, 255); //color azul
    const tituloWidth = doc.getStringUnitWidth(titulo) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const tituloX = (doc.internal.pageSize.width - tituloWidth) / 2; // Centrar el título
    doc.text(titulo, tituloX, posicionTitulo);
    doc.setTextColor(0);


    // Dibujar una línea horizontal
    const lineWidth = doc.internal.pageSize.width - 20; // Ancho de la línea, ajusta según sea necesario
    const lineHeight = 0.5; // Grosor de la línea, ajusta según sea necesario
    const lineaY = posicionTitulo + 2; // Ajusta la posición vertical de la línea según tus necesidades
    doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    doc.setLineWidth(lineHeight);
    doc.line(10, lineaY, 10 + lineWidth, lineaY); // Dibuja la línea horizontal

    // Calcular la posición de la línea para los datos del cliente
    const lineaYCliente = posicionFecha + 5; // Ajusta según sea necesario

    // Datos del Cliente para la cabecera
    const nombreCliente = `Cliente: ${data?.nombreCliente}`
    const codigoCliente = `Código: ${data?.codigoCliente}`
    const domicilioCliente = `Dirección: ${data?.domicilioCliente}`
    const telefonoCliente = `Telefono: ${data?.telefonoCliente}`
    const depositoCleinte = `Depósito: ${data?.codDeposito}`

    // Coordenadas para la disposición de los datos del cliente
    const xLeft = 10; // Coordenada X para el lado izquierdo
    const xRight = doc.internal.pageSize.width / 2; // Coordenada X para el lado derecho
    const yTop = lineaY + 5; // Coordenada Y para la parte superior

    // Establecer la fuente y el tamaño de fuente para los datos del cliente
    doc.setFontSize(12);

    // Nombre del cliente, Código y Teléfono en la misma línea
    doc.text(nombreCliente, xLeft, yTop);
    doc.text(codigoCliente, xRight, yTop);
    doc.text(depositoCleinte, xRight + 30, yTop); // Ajusta la posición según sea necesario
    // Dirección debajo de los datos anteriores
    doc.text(domicilioCliente, xLeft, yTop + 6); // Ajusta la posición según sea necesario
    

    // Dibujar una línea horizontal para los datos del cliente
    doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    doc.setLineWidth(lineHeight);
    doc.line(10, lineaYCliente + 10, 10 + lineWidth, lineaYCliente + 10); // Dibuja la línea horizontal


    doc.setFont('helvetica'); // Restaurar la fuente original (sin negrita)

    const columnas = ['Código', 'Descripción', 'Entrada', 'Salida', 'Saldo'];

    const registros = filteredData?.map((dato, index) => {
        return [
            dato?.Codarticulo,
            dato?.Descarticulo,
            dato?.Entrada.toFixed(3),
            dato?.Salida.toFixed(3),
            dato?.Saldo.toFixed(3)
        ]
    })

    let firstPage = true;

    doc.autoTable({
        startY: 45, // Posición inicial en la página
        head: [columnas],
        body: registros,
        headStyles: { halign: 'center', fontSize: 7, textColor: [0, 0, 0], fillColor: [173, 216, 230] },
        columnStyles: {
            0: { halign: 'center', fontSize: 7 },
            1: { halign: 'center', fontSize: 7 },
            2: { halign: 'right', fontSize: 7 },
            3: { halign: 'right', fontSize: 7 },
            4: { halign: 'right', fontSize: 7 },
        },
        tableWidth: 'auto', // Ajustar el ancho automáticamente dentro de los márgenes
        margin: { top: firstPage ? 10 : 56, right: 10, bottom: 20, left: 10 }, // Márgenes
        tableLineWidth: 0.2, // Ancho del borde de la tabla
        tableLineColor: [0, 0, 0], // Color del borde de la tabla (en este caso, negro)
        didDrawPage: (data) => {
            // Agregar número de página
            doc.setFont('helvetica')
            const pageNumber = data.pageNumber;
            doc.text('Página N°: ' + pageNumber, 100, doc.internal.pageSize.height - 10);

            // Resetear la variable firstPage después de la primera página
            firstPage = false;
        }
    });


    // Crear un Blob del PDF y abrirlo en una nueva pestaña
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');



}