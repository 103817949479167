

export default function CabeceraPendienteFacturar() {
    return (
        <thead>
            <tr className="bg-black sticky top-0  border-b left-auto">
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-6 px-7 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Fecha
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-2 px-7 border-r cursor-pointer text-sm font-semibold text-white" title="Entrada / Salida">
                    <div className="flex items-center justify-center">
                        E/S
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-3 px-6 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        N°Comprobante
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-4 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Depósito">
                    <div className="flex items-center justify-center">
                        Dep
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-3 px-2 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Código
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-36 px-44 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Descripción
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-4 px-6 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center">
                        % IVA
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-2 px-2 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center">
                        Precio
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-5 px-6 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center">
                        Cantidad
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 px-4 lg:px-1.5 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center ">
                        Moneda
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 px-44 lg:px-10 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center ">
                        Comentario
                    </div>
                </th>
            </tr>
        </thead>
    )
}
