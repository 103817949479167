export default function DetalleMovStockPesificado({ detalleMovStock, onClose, mensajeError, movVenta }) {

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-60" onClick={onClose}></div>
            <div className="relative bg-white p-6 z-50 border border-black w-full max-w-6xl max-h-5xl overflow-hidden">
                <h2 className="text-xl text-center font-semibold -mt-4">DETALLE COMPROBANTE</h2>
                <p className="text-center font-semibold mb-0.5 text-green-500">{movVenta?.Letrafactura ? movVenta?.Letrafactura + '-' : ''}{movVenta?.Talonario}-{movVenta?.Nrocomprobante} - {movVenta?.Fecha}</p>
                
                {
                    movVenta?.Notamovimiento?.length > 0 ? (
                        <p className="text-center font-semibold text-sm mb-0.5 -mt-1">Nota: {movVenta?.Notamovimiento}</p>
                    ) :
                        ''
                }

                <div className="overflow-auto max-h-72 ">
                    <table className="min-w-full border-collapse ">
                        <thead className="bg-black text-white sticky top-0">
                            <tr className="">
                                <th className="px-2  text-sm  font-semibold w-5 border border-white">Código</th>
                                <th className="p-0.5  lg:px-4 px-32 text-sm font-semibold border border-white">Descripción</th>
                                <th className="p-0.5 lg:px-2 px-5 text-sm font-semibold w-5 border border-white">Cantidad</th>
                                <th className="p-0.5 lg:px-4 px-12 text-sm font-semibold border w-32 border-white">Precio</th>
                                <th className="p-0.5 lg:px-2 px-4 text-sm font-semibold  border w-12 border-white">IVA</th>
                                <th className="p-0.5 lg:px-4 px-10 text-sm font-semibold border w-32 border-white">Importe</th>
                                <th className="p-0.5 lg:px-4 px-8 text-sm font-semibold border w-20 border-white">Dólar</th>
                                <th className="p-0.5 lg:px-2 px-4 text-sm font-semibold w-5 border border-white">Depósito</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mensajeError ? (
                                <tr>
                                    <td colSpan="8" className="text-red-500 font-bold text-xl text-center py-4">{mensajeError}</td>
                                </tr>
                            ) : (
                                detalleMovStock?.map((detalle, index) => (
                                    <tr key={index} className="hover:gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300">
                                        <td className=" text-sm text-center border border-gray-500">{detalle?.Codarticulo}</td>
                                        <td className=" pl-0.5 text-sm text-left border border-gray-500">{detalle?.Descarticulo}</td>
                                        <td className="px-0.5 text-sm text-right border border-gray-500">{detalle?.Cantidad}</td>
                                        <td className="px-0.5 text-sm text-right border border-gray-500">{(detalle?.Precioarticulo * movVenta?.Cotizdolar)?.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
                                        <td className="px-0.5 text-sm text-right border border-gray-500">{detalle?.Poriva}</td>
                                        <td className="px-0.5 text-sm text-right border border-gray-500">{(detalle?.Importe * movVenta?.Cotizdolar)?.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
                                        <td className="px-0.5 text-sm text-right border border-gray-500">{movVenta?.Cotizdolar?.toFixed(4)}</td>
                                        <td className="px-0.5 text-sm text-center border border-gray-500">{detalle?.Coddeposito}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center items-center mt-4 -mb-4">
                    <button
                        className="px-4 py-0.5 w-60 border border-black text-center bg-red-600 text-white font-semibold hover:bg-red-700"
                        onClick={onClose}
                    >
                        CERRAR
                    </button>
                </div>
            </div>
        </div>
    );
}
