import { Route, Routes } from "react-router-dom";
import Layout from "./components/PaginaPrincipal/Layout/Layout";
// import Home from "./components/PaginaPrincipal/Home/Home";
import Login from "./components/Clientes/Login/Login";
import LayoutCliente from "./components/Clientes/Layout/LayoutCliente";
import { ClienteProvider } from "./contexts/ClienteContext";
import { EmpresaProvider } from "./contexts/EmpresaContext";
// import { SucursalProvider } from "./contexts/SucursalContext";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import TablaMoVentasDolares from "./components/Clientes/Tablas/TablaMovVentasDolares/TablaMoVentasDolares";
import TablaMovVentasPesificado from "./components/Clientes/Tablas/TablaMovVentasPesificados/TablaMovVentasPesificado";
import TablaRemitos from "./components/Clientes/Tablas/TablaRemitos/TablaRemitos";
import TablaMovVentasPesos from "./components/Clientes/Tablas/TablaMovVentasPesos/TablaMovVentasPesos";
import TablaPendienteFacturar from "./components/Clientes/Tablas/TablaPendienteFacturar/TablaPendienteFacturar";
import TablaStockVentaAnticipada from "./components/Clientes/Tablas/TablaStockVentaAnticipada/TablaStockVentaAnticipada";
import HomeCliente from "./components/Clientes/Home/HomeCliente";
import LoginSucursal from "./components/Sucursales/Login/LoginSucursal";
import LayoutSucursal from "./components/Sucursales/Layout/LayoutSucursal";
import HomeSucursal from "./components/Sucursales/Home/HomeSucursal";
import Grafico from "./components/Sucursales/Grafico/Grafico";




function App() {
  return (
    <div className="">


      <ClienteProvider>
        <EmpresaProvider>
          {/* <SucursalProvider> */}



            <Routes>
              {/* Seccion Pagina Principal */}
              <Route path="/" element={<Layout />} >
                {/* <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />   */}
              </Route>
            
               
              {/* Seccion Clientes */}
              <Route path="/login" element={<Login />} />
              <Route path="/layoutCliente" element={<LayoutCliente />} >
                <Route index element={<HomeCliente />} />
                <Route path="HomeCliente" element={<HomeCliente />} />
                <Route path="tablaMovVentasDolares" element={<TablaMoVentasDolares />} />
                <Route path="tablaMovVentasPesificados" element={<TablaMovVentasPesificado />} />
                <Route path="tablaMovVentasPesos" element={<TablaMovVentasPesos />} />
                <Route path="tablaRemitos" element={<TablaRemitos />} />
                <Route path="tablaPendienteFacturar" element={<TablaPendienteFacturar />} />
                <Route path="tablaStockVentaAnticipada" element={<TablaStockVentaAnticipada />} />
              </Route>

              {/* Seccion Sucursales */}
              <Route path="/loginSucursal" element={<LoginSucursal />} />
              <Route path="/layoutSucursal" element={<LayoutSucursal />} >
                <Route index element={<HomeSucursal />} />
                <Route path="HomeSucursal" element={<HomeSucursal />} />
                <Route path="Grafico" element={<Grafico />} />
              </Route>
              

              <Route path="*" element={<PageNotFound />} />

            </Routes>


          {/* </SucursalProvider> */}
        </EmpresaProvider>
      </ClienteProvider>

    </div>
  );
}

export default App;
