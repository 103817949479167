import imagenfondo from '../../../images/logo-sur.jpg'

export default function HomeSucursal() {
    return (
        <>
            <div className='w-full h-full   bg-center bg-no-repeat' style={{ backgroundImage: `url(${imagenfondo})` }}>

            </div>
            <div className='bg-black p-2'>
                <p className='text-center text-black font-serif'>Integral Agropecuaria S.R.L</p>
            </div>
        </>
    )
}
