import { useEmpresaContext } from '../../../contexts/EmpresaContext';


export default function DetalleRemito({ detalleMovStock, onClose, remito }) {

    const { getEmpresa } = useEmpresaContext()
    const empresa = getEmpresa()


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-black opacity-60" onClick={onClose}></div>
            <div className="relative bg-white p-6 z-50 border border-black w-full max-w-6xl  max-h-5xl overflow-hidden">
                <h2 className="text-xl text-center font-semibold  -mt-4">DETALLE REMITO</h2>
                <p className="text-center font-semibold mb-0.5 text-green-500">{remito?.Talonario}-{remito?.Nrocomprobante} - {remito?.Fecha}</p>

                <div className="overflow-auto max-h-72 ">
                    <table className="min-w-full border-collapse ">
                        <thead className="bg-black text-white sticky top-0">
                            <tr className="">
                                <th className="lg:px-2 px-5  text-sm  font-semibold w-5 border border-white">Código</th>
                                <th className="p-0.5 lg:px-4 px-28 text-sm font-semibold border border-white">Descripción</th>
                                <th className="p-0.5 lg:px-2 px-5 text-sm font-semibold w-5 border border-white">Cantidad</th>
                                <th className="p-0.5 lg:px-4 px-5 text-sm font-semibold border w-32 border-white">Precio</th>
                                <th className="p-0.5 lg:px-4 px-20 text-sm font-semibold border w-48 border-white">ESTADO</th>
                            </tr>
                        </thead>
                        <tbody>

                            {detalleMovStock ? (
                                detalleMovStock?.map((detalle, index) => (
                                    <tr key={index} className="hover:gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300">
                                        <td className=" text-sm text-center border border-gray-500">{detalle?.Codarticulo}</td>
                                        <td className="pl-0.5 text-sm text-left border border-gray-500">{detalle?.Descarticulo}</td>
                                        <td className="px-0.5 text-sm text-right border border-gray-500">{detalle?.Cantidad}</td>
                                        <td className="px-0.5 text-sm text-right border border-gray-500">
                                            {empresa.mostrar_precio === 'SI' ?
                                                remito?.Dolar == '1' ? `USD ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(detalle?.Precioarticulo)}` : `${detalle?.Precioarticulo.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}`
                                                : '0'}
                                        </td>
                                        <td className="px-0.5  text-sm text-center border border-gray-500">
                                            {
                                                detalle?.Gfaidfactura == '0' && remito?.Genfactura != '0' ?
                                                    <p className="text-blue-600 font-semibold">PENDIENTE DE FACTURA</p> :
                                                    detalle?.Talonario && detalle?.Nrocomprobante ?
                                                        <p className="text-green-700 font-semibold">{'FAC N°: ' + detalle?.Talonario + '-' + detalle?.Nrocomprobante}</p> :
                                                        remito?.Genfactura == '0' ? <p className="text-red-600 font-semibold">NO GENERA FACTURA</p> : 'FALTA DATO'
                                            }
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-red-500 font-bold text-xl text-center py-4">NO SE ENCONTRARON DETALLES</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                <div className="flex justify-center items-center mt-4 -mb-4">
                    <button
                        className="px-4 py-0.5 w-60 border border-black text-center bg-red-600 text-white font-semibold hover:bg-red-700"
                        onClick={onClose}
                    >
                        CERRAR
                    </button>
                </div>
            </div>
        </div>
    );
}
