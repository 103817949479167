import { useState, useEffect } from "react";
import axios from 'axios';
import { BarChart, Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from "recharts";

export default function Grafico() {

    const [movventas, setMovventas] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/movventas/sucursal/sur`);
            setMovventas(response?.data);
        } catch (error) {
            console.log(error);
        }
    };

    // Función para convertir el número de mes en nombre del mes
    const getNombreMes = (mesNumero) => {
        const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        return meses[parseInt(mesNumero) - 1]; // -1 porque los arrays empiezan en 0
    };

    // Mapear los datos de ventas para agregar el nombre del mes
    const dataConMeses = movventas?.map(item => ({
        ...item,
        mes: getNombreMes(item.mes)
    }));

    return (
        <div className="flex justify-center items-center h-screen">
            
            <div className="flex justify-center items-center w-full h-full p-10">
                <ResponsiveContainer width='80%' height='80%'>
                    <BarChart
                        data={dataConMeses} // Datos para el gráfico
                        width={500} // Ancho del gráfico
                        height={300} // Altura del gráfico
                        margin={{ // Margen alrededor del gráfico
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                        >
                        <CartesianGrid strokeDasharray='3 3' /> {/* Línea de la cuadrícula */}
                        <XAxis dataKey="mes" /> {/* Eje X con los nombres de los meses */}
                        <YAxis /> {/* Eje Y para los valores numéricos */}
                        <Tooltip /> {/* Muestra información al pasar el ratón sobre las barras */}
                        <Legend /> Muestra la leyenda del gráfico
                        <Bar dataKey="Total" fill="#8884d8" /> {/* Barras con el total de ventas por mes */}
                    </BarChart>
                        <p className="text-center font-bold">AÑO: 2023</p>
                </ResponsiveContainer>
            </div>

        
        </div>
    );
}
