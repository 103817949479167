import { createContext, useContext, useState } from 'react'

// Creaer el contexto
const SucursalContext = createContext();

// Proveedor del contexto
export const SucursalProvider = ({ children }) => {
    const [sucursal, setSucursal] = useState('')

    // Funcion para guardar los datos de la sucursal
    const addSucursal = (data) => {
        setSucursal(data)
    }

    // Funcion para obtener los datos de la sucursal guardado
    const getSucursal = () => {
        return sucursal
    }

    // Se proporcionan las funciones
    const contextValue = {
        addSucursal,
        getSucursal
    }

    return (
        <SucursalContext.Provider value={contextValue}>
            {children}
        </SucursalContext.Provider>
    )
}

// Funcion de utilidad para acceder al contexto
export const useSucursalContext = () => {
    return useContext(SucursalContext)
}