import { createContext, useContext, useState } from 'react'

// Creaer el contexto
const EmpresaContext = createContext();

// Proveedor del contexto
export const EmpresaProvider = ({ children }) => {
    const [empresa, setEmpresa] = useState('')

    // Funcion para guardar los datos del cliente
    const addEmpresa = (data) => {
        setEmpresa(data)
    }

    // Funcion para obtener los datos del cliente guardado
    const getEmpresa = () => {
        return empresa
    }

    // Se proporcionan las funciones
    const contextValue = {
        addEmpresa,
        getEmpresa
    }

    return (
        <EmpresaContext.Provider value={contextValue}>
            {children}
        </EmpresaContext.Provider>
    )
}

// Funcion de utilidad para acceder al contexto
export const useEmpresaContext = () => {
    return useContext(EmpresaContext)
}