

export const calcularSaldoMovFiltradosPesificados = (lista, saldoAnterior) => {
    let saldo = saldoAnterior;

    const data = lista?.map(item => {
        let debe = 0;
        let haber = 0;
        let multiplicador;
        let Totalgral;

        // Determinar el multiplicador según las condiciones especificadas
        if (item.Dolar == '1') {
            Totalgral = item.Totalgral
            multiplicador = item.Cotizdolar;
        } else if (item.Dolar == '0' && item.idCobranza != '0' && item.Numerorto == '$') {
            Totalgral = item.Neto1 + item.Neto2 + item.Neto3 + item.Impiva1 + item.Impiva2 + item.Impiva3
            multiplicador = 1;
        } else if (item.Dolar == '0' && item.idCobranza != '0' && item.Numerorto == '+') {
            Totalgral = item.Neto1 + item.Neto2 + item.Neto3
            multiplicador = 1;
        } else {
            Totalgral = item.Totalgral
            multiplicador = 1
        }

        // Calcular debe y haber basado en Tipomov
        if (item.Tipomov == 'F' || item.Tipomov == 'D') {
            debe = Totalgral * multiplicador;
        } else if (item.Tipomov == 'C' || item.Tipomov == 'R') {
            haber = Totalgral * multiplicador;
        }

        // Actualizar saldo
        saldo += debe - haber;

        return {
            ...item,
            debe,
            haber,
            saldo,
        };
    });

    return data;
};
