import Footer from '../Footer/Footer';
import Home from '../Home/Home';
import Navbar from '../Navbar/Navbar';



export default function Layout() {

    return (

        <div className='h-screen grid grid-cols-1 bg-white'>
            <div className=' '>
                <Navbar />
            </div>

            <div className='self-center h-full w-full'>
                <Home />
            </div>
            
            <div className='self-end'>
                <Footer />
            </div>
        </div>
    );
}