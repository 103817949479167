import { useState } from 'react';
import pdf from '../../../../images/archivo-pdf.png'
import libro from '../../../../images/libro.png'
import DetalleRemito from '../../DetalleRemito/DetalleRemito';


export default function BodyRemitosFiltrados({ registrosFiltrados }) {

    const [detalleMovStock, setDetalleMovStock] = useState([]);
    const [showDetalle, setShowDetalle] = useState(false);
    // Aca alamceno los datos del registro que hago click
    const [selectedRem, setSelectedRem] = useState('');


    const handleVisualizarDetalle = async (Detalles, rem) => {
        setSelectedRem(rem)
        setDetalleMovStock(Detalles)
        setShowDetalle(true);
    };

    const closeModal = () => {
        setShowDetalle(false);
        setDetalleMovStock('');
    };

    const handleDescargarPdf = (base64Pdf) => {
        // Decodificar la cadena base64 a caracteres binarios
        const caracteresBinarios = atob(base64Pdf);
        // Crear un array para almacenar los valores de los caracteres binarios
        const numerosBinarios = new Array(caracteresBinarios?.length);
        // Convertir cada carácter binario a su valor numérico correspondiente
        for (let i = 0; i < caracteresBinarios?.length; i++) {
            numerosBinarios[i] = caracteresBinarios?.charCodeAt(i);
        }
        // Crear un Uint8Array a partir del array de valores numéricos
        const arrayBinario = new Uint8Array(numerosBinarios);
        // Crear un Blob a partir del Uint8Array, especificando que es un PDF
        const blob = new Blob([arrayBinario], { type: 'application/pdf' });
        // Crear una URL para el Blob
        const urlBlob = URL.createObjectURL(blob);
        // Abrir la URL en una nueva ventana
        window.open(urlBlob, '_blank');
    };


    return (
        <tbody className="" >
            {registrosFiltrados?.map((rem, index) => (
                <tr key={index} className="bg-opacity-70 bg-white text-center text-sm font-semibold hover:gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300">
                    <td className="p-0.5 border">{rem?.Fecha}</td>
                    <td className="p-0.5 border" >{rem?.Codadminis}</td>
                    <td className="p-0.5 border text-right pr-1" >{rem?.Talonario}-{rem?.Nrocomprobante}</td>
                    <td className="p-0.5 border">{rem?.Tipomov}</td>
                    <td className="p-0.5 border">{rem?.Deposito}</td>
                    <td
                        className="p-0.5 border cursor-pointer"
                        title={rem?.Comentarios}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '200px',
                        }}
                    >
                        {rem?.Comentarios}
                    </td>
                    <td className={`p-0.5 border ${rem?.Detalles[0]?.Gfaidfactura == '0' && rem?.Genfactura != '0' ? 'text-blue-600' : rem?.Genfactura == '0' ? 'text-red-600' : 'text-green-600'}`}>
                        {
                            rem?.Detalles[0]?.Gfaidfactura == '0' && rem?.Genfactura != '0' ?
                                'PENDIENTE DE FACTURA' :
                                rem?.Detalles[0]?.Talonario && rem?.Detalles[0]?.Nrocomprobante ?
                                    'FAC N°: ' + rem?.Detalles[0]?.Talonario + '-' + rem?.Detalles[0]?.Nrocomprobante :
                                    rem?.Genfactura == '0' ? 'NO GENERA FACTURA' : 'FALTA TALONARIO Y COMPROBANTE'
                        }
                    </td>
                    <td className="p-0.5 border" >
                        {rem?.Propiedad == '1' ? 'PROPIO' : rem?.Propiedad == '2' ? 'VTA.DIRECTA' : rem?.Propiedad == '3' ? 'SIEMBRA' : rem?.Propiedad == '4' ? 'CTA. Y ORDEN' : rem?.Propiedad == '5' ? 'PRESTAMOS' : rem?.Propiedad == '6' ? 'CONSIGNADOS' : ''}
                    </td>
                    <td className="p-0.5 border">{rem?.Vtaanticipada == '1' ? '✔' : ''}</td>
                    <td className="p-0.5 border">{rem?.Muevestock == '1' ? '✔' : ''}</td>
                    <td className="p-0.5 border" >{rem?.Genfactura == '1' ? '✔' : ''}</td>
                    <td title="Visualizar Detalle" className="p-0.5 border" >
                        <button onClick={() => handleVisualizarDetalle(rem?.Detalles, rem)}>
                            <img src={libro} alt="libro" className="lg:w-5 w-7 h-7 lg:h-5 cursor-pointer" />
                        </button>
                    </td>
                    <td title="Generar PDF" className="p-0.5 border" >
                        <button onClick={() => handleDescargarPdf(rem?.Imagen)} disabled={!rem?.Imagen?.length} className={` ${!rem?.Imagen?.length && 'opacity-0'}`} >
                            <img src={pdf} alt="pdf" className="lg:w-5 w-7 h-7 lg:h-5 cursor-pointer" />
                        </button>
                    </td>
                </tr>
            ))
            }

            {showDetalle && <DetalleRemito detalleMovStock={detalleMovStock} onClose={closeModal} remito={selectedRem} />}

        </tbody >
    )
}
