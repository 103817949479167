export const calcularSaldoAnteriorMovPesos = (lista) => {
    let saldoAnterior = 0;

    lista?.forEach(item => {
        let debe = 0;
        let haber = 0;

        if (item.Tipomov == 'F' || item.Tipomov == 'D') {
            debe = item.Totalgral;
        } else if (item.Tipomov == 'C' || item.Tipomov == 'R') {
            haber = item.Totalgral;
        }

        saldoAnterior += debe - haber;
    });

    return saldoAnterior;
};