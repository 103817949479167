import { useEffect, useState } from 'react';
import axios from 'axios';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSucursalContext } from '../../../contexts/SucursalContext';
import Navbar from '../Navbar/NavbarSucursal';
import MensajeToken from '../MensajeTokenSucursal/MensajeTokenSucursal';
import PerfilSucursal from '../PerfilSucursal/PerfilSucursal';


export default function LayoutSucursal() {

  const navigate = useNavigate()

  const { addSucursal } = useSucursalContext()

  const [mensajeError, setMensajeError] = useState('')
  const [toggleMenu, setToggleMenu] = useState(false);

  const [isModalOpenPerfil, setIsModalOpenPerfil] = useState(false);


  useEffect(() => {
    getSucursal()

  }, [])

  const getSucursal = async () => {
    try {
      // Obtén el token de localStorage
      const token = localStorage.getItem('auth_sucursal');
      if (!token) {
        navigate('/loginSucursal');
      }

      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/home/sucursal/sur`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      addSucursal(response.data.datosSucursal.datosSucursal);


    } catch (error) {
      
      setMensajeError(error.response?.data?.message || error.message);

      switch (error.response?.data?.message) {
        case 'El tiempo ha expirado. Vuelva a iniciar sesion':
          setToggleMenu(!toggleMenu)
          setTimeout(() => {
            localStorage.removeItem('auth_sucursal');
            navigate("/loginSucursal")
          }, 10000);
          break;
        case 'Acceso denegado. Token no proporcionado.':
          navigate("/loginSucursal")
          break;
        default:
          break;
      }
    }
  }

  const logout = async () => {
    try {
      // Elimina el token de localStorage
      localStorage.removeItem('auth_sucursal');
      navigate('/loginSucursal');
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };



  return (

    <div className="h-screen flex flex-col">
      <div className="flex-shrink-0">
        <Navbar logout={logout} setIsModalOpenPerfil={setIsModalOpenPerfil} />
      </div>

      {/* <div className="flex-grow overflow-auto"> */}
      {/* <div className=""> */}
      <Outlet />
      {/* </div> */}
      {/* </div> */}

      {toggleMenu && <MensajeToken mensajeError={mensajeError} logout={logout} />}

      {isModalOpenPerfil && <PerfilSucursal setIsModalOpenPerfil={setIsModalOpenPerfil} />}


    </div>
  );
}