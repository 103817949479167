import { useState } from 'react';
import logo from '../../../images/logo-integ.png'


export default function Navbar({ logout, setIsModalOpenPerfil }) {

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };


  return (
    <nav className="bg-black px-2 lg:px-2 py-1 shadow fixed top-0 left-0 right-0 z-50 ">
      <div className="container flex justify-between items-center mx-auto ">

        {/* Logo on the left */}
        <div className='flex justify-start items-center'>
          <a href="/layoutSucursal/HomeSucursal" className="flex items-center transition-transform hover:scale-95">
            <img src={logo} alt="logo" className="w-8 h-8" />
          </a>
        </div>

        {/* Menu items centered */}
        <div className="flex-grow flex justify-center">
          <div className={`w-full md:block md:w-auto ${open ? 'block' : 'hidden'}`} id="mobile-menu">
            <ul className="flex flex-col mt-5 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium lg:px-4">

              <li className='text-md'>
                <a
                  href="/layoutSucursal/Grafico"
                  className="block    rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
                >
                  Gráfico
                </a>
              </li>

              <li className='text-md'>
                <a
                  href="#"
                  className="block    rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
                >
                  Prueba 2
                </a>
              </li>

              <li className='text-md' >
                <a
                  href="#"
                  className="block    rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
                >
                  Prueba 3
                </a>
              </li>

              <li className='text-md'>
                <button
                  onClick={() => setIsModalOpenPerfil(true)}
                  type='button'
                  className="block    rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-white md:hover:text-white font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700"
                >
                  Perfil
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* Logout button on the right */}
        <div className="flex justify-end  items-center ">
          <button
            onClick={logout}
            className='flex items-center text-md rounded-md text-white md:text-gray lg:text-gray-400 md:text-gray-400 lg:hover:text-red-500 md:hover:text-red-500 font-bold p-1 hover:bg-gray-700 lg:hover:bg-gray-700 md:hover:bg-gray-700'>
            Cerrar Sesión
          </button>
        </div>

        {/* Mobile menu button */}
        <div className="flex items-center md:hidden">
          <button
            id="menu-toggle"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  );
}