import { useState } from 'react';
import axios from 'axios'
import { calcularSaldoMovFiltradosDolares } from "../../../../services/calcularSaldoMovFiltradosDolares"
import pdf from '../../../../images/archivo-pdf.png'
import libro from '../../../../images/libro.png'
import DetalleMovStockDolares from '../../DetalleMovStock/DetalleMovStockDolares';

export default function BodyMovVentasDolaresFiltrados({ registrosFiltrados, saldoAnterior }) {

    const sucursal = localStorage.getItem('sucursal')

    const saldoCalculadoMovDolares = calcularSaldoMovFiltradosDolares(registrosFiltrados, saldoAnterior)

    const [detalleMovStock, setDetalleMovStock] = useState([]);
    const [showDetalle, setShowDetalle] = useState(false);
    const [mensajeError, setMensajeError] = useState('')
    // Aca alamceno los datos del registro que hago click
    const [selectedMov, setSelectedMov] = useState('');


    const handleVisualizarDetalle = async (Clave, mov) => {
        setSelectedMov(mov)
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/movstock/${sucursal}/movventas/${Clave}`);
            setDetalleMovStock(response?.data);
            setShowDetalle(true);
            setMensajeError('');
        } catch (error) {
            setShowDetalle(true)
            setMensajeError(error.response?.data?.message)
            console.error('Error al obtener los detalles del movStock:', error);
        }
    };

    const closeModal = () => {
        setShowDetalle(false);
        setDetalleMovStock('');
    };

    const handleDescargarPdf = (base64Pdf) => {
        // Decodificar la cadena base64 a caracteres binarios
        const caracteresBinarios = atob(base64Pdf);
        // Crear un array para almacenar los valores de los caracteres binarios
        const numerosBinarios = new Array(caracteresBinarios?.length);
        // Convertir cada carácter binario a su valor numérico correspondiente
        for (let i = 0; i < caracteresBinarios?.length; i++) {
            numerosBinarios[i] = caracteresBinarios?.charCodeAt(i);
        }
        // Crear un Uint8Array a partir del array de valores numéricos
        const arrayBinario = new Uint8Array(numerosBinarios);
        // Crear un Blob a partir del Uint8Array, especificando que es un PDF
        const blob = new Blob([arrayBinario], { type: 'application/pdf' });
        // Crear una URL para el Blob
        const urlBlob = URL.createObjectURL(blob);
        // Abrir la URL en una nueva ventana
        window.open(urlBlob, '_blank');
    };



    return (
        <tbody className="" >
            {registrosFiltrados?.map((mov, index) => (

                <tr key={index} className="bg-opacity-70 bg-white text-center text-sm font-semibold  hover:bg-gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300 ">
                    <td className="p-0.5 border" >{mov?.Fecha}</td>
                    <td className="p-0.5 border" >{mov?.Fechavto}</td>
                    <td className="p-0.5 border text-right pr-1" >{mov?.Letrafactura ? mov?.Letrafactura + ' - ' : ''} {mov?.Talonario} - {mov?.Nrocomprobante}</td>
                    <td className="p-0.5 border" >{mov?.Tipomov}</td>
                    <td className="p-0.5 border" >{mov?.Condvta}</td>
                    <td className="p-0.5 border" >{mov?.Ventaanticipada == '1' ? '✔' : ''}</td>
                    <td className="p-0.5 border text-right pr-1" >{mov?.Cotizdolar.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</td>
                    <td className="p-0.5 border text-right pr-1" >{saldoCalculadoMovDolares[index]?.debe ? 'USD ' + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoCalculadoMovDolares[index].debe) : ''}</td>
                    <td className="p-0.5 border text-right pr-1" >{saldoCalculadoMovDolares[index]?.haber ? 'USD ' + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoCalculadoMovDolares[index].haber) : ''}</td>
                    <td className="p-0.5 border font-black text-right pr-1" >USD {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoCalculadoMovDolares[index].saldo)}</td>
                    <td
                        className="p-0.5 border cursor-pointer "
                        title={mov?.Notamovimiento}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100px',
                        }}
                    >
                        {mov?.Notamovimiento}
                    </td>

                    <td className='p-0.5 border' title='Visualizar Detalle'>
                        <button onClick={() => handleVisualizarDetalle(mov?.Clave, mov)}>
                            <img src={libro} alt="libro" className="lg:w-5 lg:h-5 w-7 h-7 cursor-pointer" />
                        </button>
                    </td>
                    <td className='p-0.5 border' title='Generar PDF'>
                        <button onClick={() => handleDescargarPdf(mov?.Imagen)} disabled={!mov?.Imagen?.length} className={` ${!mov?.Imagen?.length && 'opacity-0 '}`} >
                            <img src={pdf} alt="pdf" className="lg:w-5 lg:h-5 w-7 h-7 cursor-pointer" />
                        </button>
                    </td>
                </tr>
            ))}



            {showDetalle && <DetalleMovStockDolares detalleMovStock={detalleMovStock} onClose={closeModal} mensajeError={mensajeError} movVenta={selectedMov} />}


        </tbody>
    )
}
