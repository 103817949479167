export const calcularSaldoTotalMovPesos = (lista) => {
    let saldoTotal = 0;

    lista?.forEach(item => {
        if (item.Tipomov == 'F' || item.Tipomov == 'D') {
            saldoTotal += item.Totalgral;
        } else if (item.Tipomov == 'C' || item.Tipomov == 'R') {
            saldoTotal -= item.Totalgral;
        }
    });

    return saldoTotal;
};