import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useClienteContext } from '../../../../contexts/ClienteContext';
import { useEmpresaContext } from '../../../../contexts/EmpresaContext';
import axios from 'axios';
import CabeceraMovVentasDolares from "./CabeceraMovVentasDolares";
import BodyMovVentasDolares from "./BodyMovVentasDolares";
import BodyMovVentasDolaresFiltrados from './BodyMovVentasDolaresFiltrados';
import FiltroFechas from '../../FiltroFechas/FiltroFechas';
import { calcularSaldoTotalMovDolares } from '../../../../services/calculaSaldoTotalMovDolares';
import { calcularSaldoAnteriorDolares } from '../../../../services/calculaSaldoAnteriorDolares';
import { calcularSaldoTotalMovDolaresFiltrados } from '../../../../services/calculaSaldoTotalMovVentasDolaresFiltrados';
import { calcularSaldoMovFiltradosDolares } from '../../../../services/calcularSaldoMovFiltradosDolares';
import { generarPdfDolares } from '../../../../services/generaPdfMovDolaresFiltrados';
import flechaArriba from '../../../../images/flecha-arriba.png'
import flechaAbajo from '../../../../images/flecha-abajo.png'
import imagenfondo from '../../../../images/logo-sur.jpg'
import imagenFondoIntegral from '../../../../images/logo-nuevo-srl.jpg'
import gifCargando from '../../../../images/cargando.gif'



export default function TablaMoVentasDolares() {

    const sucursal = localStorage.getItem('sucursal')
    // Determina la imagen de fondo según la sucursal
    const fondo = sucursal === 'sur' ? imagenfondo : imagenFondoIntegral;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const opcion = query.get('opcion');


    const { getCliente } = useClienteContext()
    const data = getCliente()
    const codigo = data.codigoCliente
    const { getEmpresa } = useEmpresaContext()
    const empresa = getEmpresa()
    

    const [movVentasDolares, setMovVentasDolares] = useState([])
    const [mensajeError, setmensajeError] = useState('')
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    // ESTADO DE CARGA PARA MOSTRAR EL MENSAJE DE CARGANDO
    const [loading, setLoading] = useState(false)
    // ESTAS SON LAS FECHAS SELECIONADAS
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    // REGISTROS FILTRADOS POR FECHAVTO
    const [registrosFiltrados, setRegistrosFiltrados] = useState([]);
    // ACA ESTAN GUARDADOS LOS REGISTROS PARA CALCULAR EL SALDO ANTERIOR
    const [registrosSaldoAnterior, setRegistrosSaldoAnterior] = useState([])
    // PARA CONTROLAR EL ESTADO DEL BOTON DE IMPIRMIR
    const [estadoImprimir, setEstadoImprimir] = useState(false)
    // ESTE ESTADO CONTROLA CUAL BODY SE DEBE MOSTRAR Y QUE DETALLE EN EL FOOTER SE DEBE MOSTRAR
    const [showFiltrado, setShowFiltrado] = useState(false)

    const tableContainerRef = useRef(null);

    const saldoTotalMovDolares = calcularSaldoTotalMovDolares(movVentasDolares)
    const saldoAnterior = calcularSaldoAnteriorDolares(registrosSaldoAnterior)
    const saldoTotalMovDolaresFiltrados = calcularSaldoTotalMovDolaresFiltrados(registrosFiltrados, saldoAnterior)
    const saldoCalculadoMovDolaresFiltrados = calcularSaldoMovFiltradosDolares(registrosFiltrados, saldoAnterior)


    useEffect(() => {

        fetchData()


        //  ESTO ES PARA CETEAR LOS INPUT CON LAS FECHAS DEL PRIMER DIA DEL MES Y EL ULTIMO DIA DEL MES ACTUAL
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        const formatDate = (date) => date.toISOString().split('T')[0];

        setFechaDesde(formatDate(firstDay));
        setFechaHasta(formatDate(lastDay));
        setShowFiltrado(false)
        setEstadoImprimir(false)


    }, [codigo, opcion]);


    useEffect(() => {
        if (tableContainerRef.current) {
            const { scrollHeight, clientHeight } = tableContainerRef.current;
            setShowScrollButtons(scrollHeight > clientHeight);
            tableContainerRef.current.scrollTop = scrollHeight;
        }
    }, [movVentasDolares, registrosFiltrados]);


    const fetchData = async () => {

        if (!codigo || !sucursal) {
            setmensajeError('Código de cliente o sucursal no definido');
            return;
        }

        setLoading(true); // Inicia la carga
        setmensajeError(''); // Resetea el mensaje de error

        try {

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/movventas/${sucursal}/dolares/${codigo}?opcion=${opcion}`);
            setMovVentasDolares(response?.data)
            setmensajeError('')


        } catch (error) {
            setmensajeError(error.response?.data?.message)
            console.log(error)

        } finally {
            setLoading(false); // Termina la carga
        }
    }


    const handleFiltrarClick = () => {
        const fieldToUse = opcion == 'FechavtoFormateada' ? 'FechavtoFormateada' : 'FechaFormateada';
        // CON ESTOS REGISTROS CALCULO EL SALDO ANTERIOR
        const regristrosSaldoAnterior = movVentasDolares?.filter(registro => {
            const fecha = new Date(registro[fieldToUse]);
            return fecha < new Date(fechaDesde);
        });
        setRegistrosSaldoAnterior(regristrosSaldoAnterior?.length > 0 ? regristrosSaldoAnterior : []);
        // ACA SE FILTRAN LOS REGISTROS QUE SE MUESTRAN EN LA TABLA
        const registrosFiltrados = movVentasDolares?.filter(registro => {
            const fecha = new Date(registro[fieldToUse]);
            return fecha >= new Date(fechaDesde) && fecha <= new Date(fechaHasta);
        });
        setRegistrosFiltrados(registrosFiltrados?.length > 0 ? registrosFiltrados : []);
        setShowFiltrado(true);
        setmensajeError(registrosFiltrados?.length > 0 ? '' : 'NO EXISTEN REGISTROS EN EL RANGO DE FECHAS');
        // HABILITAR EL BOTON DE IMPRIMIR SOLO SI HAY REGISTROS FILTRADOS
        setEstadoImprimir(registrosFiltrados?.length > 0);
    };

    const handleImprimirClick = () => {
        generarPdfDolares(registrosFiltrados, data, empresa, fechaDesde, fechaHasta, saldoAnterior, saldoCalculadoMovDolaresFiltrados, saldoTotalMovDolaresFiltrados, opcion);
    };

    const scrollToTop = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
    };

    const scrollToBottom = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
        }
    };


    return (
        <>
            <div className=' -p-4 -mb-12 flex justify-center items-center  border border-black bg-gradient-to-r from-black to-gray-800' style={{ background: '#ffff00' }}>
                <p className='font-bold text-center '>HISTORICO EN DOLARES POR {opcion == 'FechavtoFormateada' ? 'FECHA DE VENCIMIENTO' : 'FECHA DE FACTURA'}</p>
            </div>

            <div className='mt-12  -p-4 -mb-12 flex justify-center items-center  border border-black' style={{ background: '#ffff00' }}>
                <p className='font-semibold text-sm'>Última Actualización: {new Date(empresa?.updatedAt)?.toLocaleString('es-ES', { hour12: false })}</p>
            </div>

            <div className="flex-shrink-0 flex justify-center items-center   mt-0 lg:mt-2 -mb-0.5">
                <div className='flex-grow lg:flex justify-center items-center mb-0.5 lg:mb-0 mt-12 p-1 lg:p-1 lg:mt-10 bg-white'>
                    <FiltroFechas handleFiltrarClick={handleFiltrarClick} estadoImprimir={estadoImprimir} handleImprimirClick={handleImprimirClick} setFechaDesde={setFechaDesde} setFechaHasta={setFechaHasta} fechaDesde={fechaDesde} fechaHasta={fechaHasta} />
                </div>
            </div>


            {
                showFiltrado && (

                    <div className='flex justify-center items-center border border-black ' style={{ background: '#ffff00' }}>
                        <p className='text-center text-black font-bold'>SALDO ANTERIOR: USD {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoAnterior)}</p>
                    </div>

                )
            }


            <div className="flex-grow overflow-auto">
                <div className="w-full overflow-auto  h-full relative  bg-center bg-no-repeat  " ref={tableContainerRef} style={{ backgroundImage: `url(${fondo})` }} >

                    <table className="w-full border" >
                        <CabeceraMovVentasDolares />
                        {
                            showFiltrado ? (
                                <BodyMovVentasDolaresFiltrados registrosFiltrados={registrosFiltrados} saldoAnterior={saldoAnterior} />
                            ) : (

                                <BodyMovVentasDolares movVentasDolares={movVentasDolares} />
                            )
                        }

                    </table>



                    {
                        loading && <div className='flex justify-center items-center mt-6'>
                            <img src={gifCargando} alt="gif cargando" />
                        </div>
                    }

                    {
                        !loading && mensajeError && <div className='bg-red-500  border-2 border-black'><p className='text-center text-white font-bold  text-xl'>{mensajeError}</p></div>
                    }



                    {showScrollButtons && (
                        <>
                            <button
                                onClick={scrollToBottom}
                                className="fixed bottom-1 right-6 bg-transparent p-0 m-0 border-none cursor-pointer"
                                aria-label="Desplazar hacia abajo">
                                <img src={flechaAbajo} alt="Desplazar hacia abajo" className="lg:w-7 lg:h-7 w-10 h-10 transition-transform hover:scale-95" />
                            </button>
                            <button
                                onClick={scrollToTop}
                                className="fixed bottom-1 right-16 bg-transparent p-0 m-0 border-none cursor-pointer mr-6 lg:mr-0"
                                aria-label="Desplazar hacia arriba">
                                <img src={flechaArriba} alt="Desplazar hacia arriba" className="lg:w-7 lg:h-7 w-10 h-10 transition-transform hover:scale-95" />
                            </button>
                        </>
                    )}
                </div>
            </div>




            <div className='bg-black lg:p-2 p-3.5'>


                <p className='lg:text-center text-white text-sm lg:text-md font-semibold'>SALDO TOTAL:  USD {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoTotalMovDolares)}</p>



            </div>



        </>


    )
}