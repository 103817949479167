
export default function CabeceraStockVentaAnticipada() {
    return (
        <thead>
            <tr className="bg-black sticky top-0  border-b left-auto">
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-2 px-7 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Código
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-72 px-52 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Descripción
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-5 px-10 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Entrada
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-5 px-10 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Salida
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-5 px-10 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Saldo
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Detalle
                    </div>
                </th>
            </tr>
        </thead>
    )
}
