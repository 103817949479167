import { useState } from 'react';
import libro from '../../../../images/libro.png'
import DetalleStockVentaAnticipada from '../../DetalleStockVentaAnticipada/DetalleStockVentaAnticipada';


export default function BodyStockVentaAnticipada({ movStockVentaAnticipada }) {

  const [detalleMovStock, setDetalleMovStock] = useState([]);
  // console.log(detalleMovStock)
  const [showDetalle, setShowDetalle] = useState(false);
  // Aca alamceno los datos del registro que hago click
  const [selectedMov, setSelectedMov] = useState('');

  const handleVisualizarDetalle = async (Detalle, mov) => {
    setSelectedMov(mov)
    setDetalleMovStock(Detalle)
    setShowDetalle(true);
  };

  const closeModal = () => {
    setShowDetalle(false);
    setDetalleMovStock('');
  };

  return (
    <>
      <tbody className="">
        {movStockVentaAnticipada?.map((mov, index) => (
          <tr key={index} className="bg-opacity-70 bg-white text-center text-sm font-semibold hover:gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300">
            <td className="p-0.5 border">{mov?.Codarticulo}</td>
            <td className="p-0.5 border">{mov?.Descarticulo}</td>
            <td className="p-0.5 border text-right pr-1">{mov?.Entrada?.toFixed(3)}</td>
            <td className="p-0.5 border text-right pr-1">{mov?.Salida?.toFixed(3)}</td>
            <td className="p-0.5 border text-right pr-1">{mov?.Saldo?.toFixed(3)}</td>

            <td title="Visualizar Detalle" className="p-0.5 border">
              <button onClick={() => handleVisualizarDetalle(mov?.Detalle, mov)}>
                <img src={libro} alt="libro" className="lg:w-5 w-7 h-7 lg:h-5 cursor-pointer"/>
              </button>
            </td>
          </tr>
        ))
        }
      </tbody >

      {showDetalle && (
        <DetalleStockVentaAnticipada
          detalleMovStock={detalleMovStock}
          onClose={closeModal}
          movStockVtaAnticipada={selectedMov}
        />
      )}
    </>
  )
}