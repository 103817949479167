

export default function BodyPendienteFacturar({ remitosPendientesFacturar, empresa }) {



    return (
        <tbody className="">
            {remitosPendientesFacturar?.map((mov, index) => (
                <tr key={index} className="bg-opacity-70 bg-white text-center text-sm font-semibold hover:gray-300 md:hover:bg-gray-300 lg:hover:bg-gray-300">
                    <td className="p-0.5 border">{mov?.Fecha}</td>
                    <td className="p-0.5 border">{mov?.Tipomov}</td>
                    <td className="p-0.5 border text-right pr-1">{mov?.Talonario}-{mov?.Nrocomprobante}</td>
                    <td className="p-0.5 border">{mov?.Coddeposito}</td>
                    <td className="p-0.5 border">{mov?.Codarticulo}</td>
                    <td className="p-0.5 border">{mov?.Descarticulo}</td>
                    <td className="p-0.5 border text-right pr-1">{mov?.Poriva}</td>
                    <td className="p-0.5 border text-right pr-1">{empresa?.mostrar_precio === 'SI' ? mov?.Precioarticulo?.toFixed(2) : '0'}</td>
                    <td className="p-0.5 border text-right pr-1">{mov?.Cantidad}</td>
                    <td className="p-0.5 border">{mov?.Dolar == '1' ? 'USD' : 'ARS'}</td>
                    <td
                        className="p-0.5 border cursor-pointer "
                        title={mov?.Comentarios}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100px',
                        }}
                    >
                        {mov?.Comentarios}
                    </td>
                </tr>
            ))
            }
        </tbody>
    )
}
