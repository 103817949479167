export const calcularSaldoMovPesos = (lista) => {
    let saldo = 0;

    const data = lista?.map(item => {
        let debe = 0;
        let haber = 0;

        if (item.Tipomov == 'F' || item.Tipomov == 'D') {    
            debe = item.Totalgral;
        } else if (item.Tipomov == 'C' || item.Tipomov == 'R') {
            haber = item.Totalgral;
        }

        saldo += debe - haber;

        return {
            ...item,
            debe,
            haber,
            saldo,
        };
    });

    return data;
}