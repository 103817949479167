

export const calcularSaldoTotalMovPesificados = (lista) => {
    let saldoTotal = 0;

    lista?.forEach(item => {
        let multiplicador;
        let Totalgral;

        if (item.Dolar == '1') {
            Totalgral = item.Totalgral
            multiplicador = item.Cotizdolar;
        } else if (item.Dolar == '0' && item.idCobranza != '0' && item.Numerorto == '$') {
            Totalgral = item.Neto1 + item.Neto2 + item.Neto3 + item.Impiva1 + item.Impiva2 + item.Impiva3
            multiplicador = 1;
        } else if (item.Dolar == '0' && item.idCobranza != '0' && item.Numerorto == '+') {
            Totalgral = item.Neto1 + item.Neto2 + item.Neto3
            multiplicador = 1;
        } else {
            Totalgral = item.Totalgral
            multiplicador = 1
        }

        if (item.Tipomov == 'F' || item.Tipomov == 'D') {
            saldoTotal += Totalgral * multiplicador;
        } else if (item.Tipomov == 'C' || item.Tipomov == 'R') {
            saldoTotal -= Totalgral * multiplicador;
        }
    });

    return saldoTotal;
};

