

export default function CabeceraMovVentasPesificado() {
  return (
    <thead>
      <tr className="bg-black sticky top-0  border-b   left-auto ">
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-6 px-7 border-r cursor-pointer text-sm font-semibold text-white">
          <div className="flex items-center justify-center">
            Fecha
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-6 px-7 border-r cursor-pointer text-sm font-semibold text-white">
          <div className="flex items-center justify-center">
            FechaVto
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-10 px-10 border-r cursor-pointer text-sm font-semibold text-white">
          <div className="flex items-center justify-center">
            N°Comprobante
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Tipo Movimiento">
          <div className="flex items-center justify-center">
            T.M
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Condición Venta">
          <div className="flex items-center justify-center">
            C.V
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Venta Anticipada">
          <div className="flex items-center justify-center">
            V.A
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-8 px-9 border-r cursor-pointer text-sm font-semibold text-white" >
          <div className="flex items-center justify-center">
            Dólar
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-16 px-16 border-r cursor-pointer text-sm font-semibold text-white" >
          <div className="flex items-center justify-center">
            Debe
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-16 px-16 border-r cursor-pointer text-sm font-semibold text-white" >
          <div className="flex items-center justify-center">
            Haber
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 lg:px-20 px-20 border-r cursor-pointer text-sm font-semibold text-white" >
          <div className="flex items-center justify-center">
            Saldo
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 px-60 lg:px-0 border-r cursor-pointer text-sm font-semibold text-white" >
          <div className="flex items-center justify-center ">
            Nota Movimiento
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 px-2 lg:px-1 border-r cursor-pointer text-sm font-semibold text-white" >
          <div className="flex items-center justify-center">
            Detalle
          </div>
        </th>
        <th className="sticky top-0 p-0 py-0 lg:py-0.5 lg:p-1 px-2 lg:px-1 border-r cursor-pointer text-sm font-semibold text-white" >
          <div className="flex items-center justify-center">
            PDF
          </div>
        </th>
      </tr>
    </thead>
  )
}
