

export default function CabeceraRemitos() {
    return (
        <thead>
            <tr className="bg-black sticky top-0  border-b left-auto">
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-8 px-7 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Fecha
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-3 px-4 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Admin
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-4 px-6 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        N°Comprobante
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Tipo Movimiento">
                    <div className="flex items-center justify-center">
                        T.M
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-6 px-5 border-r cursor-pointer text-sm font-semibold text-white" title="Depósito">
                    <div className="flex items-center justify-center">
                        Dep
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-0 px-48 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center">
                        Comentario
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-20 px-24 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Estado
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-8 px-16 border-r cursor-pointer text-sm font-semibold text-white">
                    <div className="flex items-center justify-center">
                        Propiedad
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Venta Anticipada">
                    <div className="flex items-center justify-center">
                        V.A
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Mueve Stock">
                    <div className="flex items-center justify-center">
                        M.S
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 lg:px-1 px-2 border-r cursor-pointer text-sm font-semibold text-white" title="Genera Factura">
                    <div className="flex items-center justify-center">
                        G.F
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 px-2 lg:px-1 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center">
                        Detalle
                    </div>
                </th>
                <th className="sticky top-0 p-0 py-1 lg:py-0.5 lg:p-1 px-2 lg:px-1 border-r cursor-pointer text-sm font-semibold text-white" >
                    <div className="flex items-center justify-center">
                        PDF
                    </div>
                </th>
            </tr>
        </thead>
    )
}
