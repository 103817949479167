import jsPDF from 'jspdf';
import 'jspdf-autotable';



export const generarPdfDolares = (registrosFiltrados, data, empresa, fechaDesde, fechaHasta, saldoAnterior, saldoCalculadoMovDolaresFiltrados, saldoTotalMovDolaresFiltrados, opcion) => {


    const doc = new jsPDF();

    // Datos de la Empresa para cabecera
    const razonsocial = `${empresa?.Razonsocial}`;
    const direccion = `${empresa?.Domicilio} * ${empresa?.NombreLocalidad} * ${empresa?.Telefono}`;
    const email = `${empresa?.Dircorreo}`;

    // Obtener la fecha actual con formato localizado
    const formato = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fecha = `Fecha: ${new Date().toLocaleDateString('es-ES', formato)}`;

    // Configurar posición de la cabecera
    const posicionEmpresa = 10;
    const posicionDireccionEmail = 15;
    const posicionFecha = 24;
    const posicionTitulo = 24;

    // Agregar los datos de la cabecera al PDF con estilo personalizado
    doc.setFontSize(18); // Tamaño de fuente más grande para el nombre de la empresa
    doc.setFont('bold'); // Establecer la fuente en negrita
    doc.text(razonsocial, 10, posicionEmpresa);
    doc.setFont('normal'); // Restaurar la fuente a la normal después del texto en negrita

    doc.setFontSize(10); // Tamaño de fuente normal para otros datos
    // Colocar dirección y correo electrónico en la misma línea
    doc.text(direccion + ' * ' + email, 10, posicionDireccionEmail);
    doc.setFontSize(12)
    doc.text(fecha, 10, posicionFecha);

    // Establecer un título con estilo personalizado y centrado
    const titulo = 'Resumen de Cuenta en Dólares';
    doc.setFontSize(18); // Tamaño de fuente más grande para el título
    doc.setFont('bold'); // Establecer la fuente en negrita
    doc.setTextColor(0, 0, 255); //color azul
    const tituloWidth = doc.getStringUnitWidth(titulo) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const tituloX = (doc.internal.pageSize.width - tituloWidth) / 2; // Centrar el título
    doc.text(titulo, tituloX, posicionTitulo);
    doc.setTextColor(0);

    // Dibujar una línea horizontal
    const lineWidth = doc.internal.pageSize.width - 20; // Ancho de la línea, ajusta según sea necesario
    const lineHeight = 0.5; // Grosor de la línea, ajusta según sea necesario
    const lineaY = posicionTitulo + 2; // Ajusta la posición vertical de la línea según tus necesidades
    doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    doc.setLineWidth(lineHeight);
    doc.line(10, lineaY, 10 + lineWidth, lineaY); // Dibuja la línea horizontal

    // Calcular la posición de la línea para los datos del cliente
    const lineaYCliente = posicionFecha + 5; // Ajusta según sea necesario

    // Datos del Cliente para la cabecera
    const nombreCliente = `Cliente: ${data?.nombreCliente}`
    const codigoCliente = `Código: ${data?.codigoCliente}`
    const domicilioCliente = `Dirección: ${data?.domicilioCliente}`
    const telefonoCliente = `Telefono: ${data?.telefonoCliente}`

    // Coordenadas para la disposición de los datos del cliente
    const xLeft = 10; // Coordenada X para el lado izquierdo
    const xRight = doc.internal.pageSize.width / 2; // Coordenada X para el lado derecho
    const yTop = lineaY + 5; // Coordenada Y para la parte superior

    // Establecer la fuente y el tamaño de fuente para los datos del cliente
    doc.setFontSize(12);

    // Nombre del cliente, Código y Teléfono en la misma línea
    doc.text(nombreCliente, xLeft, yTop);
    doc.text(codigoCliente, xRight, yTop);
    doc.text(telefonoCliente, xRight + 30, yTop); // Ajusta la posición según sea necesario
    // Dirección debajo de los datos anteriores
    doc.text(domicilioCliente, xLeft, yTop + 6); // Ajusta la posición según sea necesario

    // Dibujar una línea horizontal para los datos del cliente
    doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    doc.setLineWidth(lineHeight);
    doc.line(10, lineaYCliente + 10, 10 + lineWidth, lineaYCliente + 10); // Dibuja la línea horizontal

    // Convierte las fechas al formato "DD/MM/YYYY"
    function formatDate(date) {
        const parts = date.split('-'); // Divide la fecha en año, mes y día
        const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`; // Formatea la fecha como "DD/MM/YYYY"
        return formattedDate;
    }

    // Coloca el período de consulta debajo de la línea de datos del cliente
    const espacioEntreLineaClienteYFecha = 15; // Ajusta el espacio necesario
    doc.setFontSize(10); // Puedes cambiar '10' al tamaño de fuente que prefieras
    doc.text(`PERIODO DE CONSULTA POR ${opcion == 'FechavtoFormateada' ? 'FECHA DE VENCIMIENTO' : 'FECHA DE FACTURA'}:`, 10, lineaYCliente + espacioEntreLineaClienteYFecha);
    doc.setFontSize(12);
    doc.text(`Desde Fecha: ${formatDate(fechaDesde)}`, 105, lineaYCliente + espacioEntreLineaClienteYFecha);
    doc.text(`Hasta Fecha: ${formatDate(fechaHasta)}`, 158, lineaYCliente + espacioEntreLineaClienteYFecha);


    // Trazar una línea horizontal después de las fechas del período de consulta
    const lineaYDespuesFechaConsulta = lineaYCliente + espacioEntreLineaClienteYFecha + 3; // Ajusta la posición vertical según sea necesario
    const lineWidthDespuesFechaConsulta = doc.internal.pageSize.width - 20; // Ajusta la longitud de la línea según sea necesario
    doc.setDrawColor(0, 0, 0); // Color de la línea (negro)
    doc.setLineWidth(0.5); // Ajusta el grosor de la línea según sea necesario
    doc.line(10, lineaYDespuesFechaConsulta, 10 + lineWidthDespuesFechaConsulta, lineaYDespuesFechaConsulta); // Dibuja la línea horizontal


    // SALDO ANTERIOR
    doc.setFont('helvetica', 'bold'); // Establecer la fuente en negrita
    const saldoAnteriorDolares = `Saldo Anterior al: ${formatDate(fechaDesde)} ${'   '} USD ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoAnterior)}`;
    doc.text(saldoAnteriorDolares, 100, 54);

    doc.setFont('helvetica'); // Restaurar la fuente original (sin negrita)

    const columnas = ['Fecha', 'FechaVto', 'N°Comprobante', 'T.M', 'C.V', 'Dólar', 'Debe', 'Haber', 'Saldo'];

    const registros = registrosFiltrados?.map((dato, index) => {
        return [
            dato?.Fecha,
            dato?.Fechavto,
            dato?.Letrafactura + '-' + dato?.Talonario + '-' + dato?.Nrocomprobante,
            dato?.Tipomov,
            dato?.Condvta,
            dato?.Cotizdolar.toLocaleString('es-AR', { style: 'currency', currency: 'ARS' }),
            saldoCalculadoMovDolaresFiltrados[index].debe ? 'USD ' +  new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoCalculadoMovDolaresFiltrados[index].debe) : '',
            saldoCalculadoMovDolaresFiltrados[index].haber ? 'USD ' + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoCalculadoMovDolaresFiltrados[index].haber) : '',
            'USD ' + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoCalculadoMovDolaresFiltrados[index].saldo)
        ]
    })

    let firstPage = true;

    doc.autoTable({
        startY: 56, // Posición inicial en la página
        head: [columnas],
        body: registros,
        headStyles: { halign: 'center',  textColor: [0, 0, 0], fillColor: [173, 216, 230] },
        columnStyles: {
            0: { halign: 'center', fontSize: 7 },
            1: { halign: 'center', fontSize: 7 },
            2: { halign: 'center', fontSize: 7 },
            3: { halign: 'center', fontSize: 7 },
            4: { halign: 'center', fontSize: 7 },
            5: { halign: 'right', fontSize: 6 },
            6: { halign: 'right', fontSize: 6 },
            7: { halign: 'right', fontSize: 6 },
            8: { halign: 'right', fontSize: 6 }
        },
        tableWidth: 'auto', // Ajustar el ancho automáticamente dentro de los márgenes
        margin: { top: firstPage ? 10 : 56, right: 10, bottom: 20, left: 10 }, // Márgenes
        tableLineWidth: 0.2, // Ancho del borde de la tabla
        tableLineColor: [0, 0, 0], // Color del borde de la tabla (en este caso, negro)
        didDrawPage: (data) => {
            // Agregar número de página
            doc.setFont('helvetica')
            const pageNumber = data.pageNumber;
            doc.text('Página N°: ' + pageNumber, 100, doc.internal.pageSize.height - 10);

            // Resetear la variable firstPage después de la primera página
            firstPage = false;
        }
    });


    // SALDO TOTAL
    doc.setFont('helvetica', 'bold'); // Establecer la fuente en negrita   
    const saldoTotal = `Saldo Total al: ${formatDate(fechaHasta)} ${'  '} USD ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(saldoTotalMovDolaresFiltrados)}`;
    const tableHeight = doc.autoTable.previous.finalY;
    const x = 108; // Ajusta la posición X según sea necesario
    const y = tableHeight + 8; // Ajusta la posición Y según sea necesario
    doc.text(saldoTotal, x, y);
    doc.setFont('helvetica'); // Restaurar la fuente original (sin negrita)



    // Crear un Blob del PDF y abrirlo en una nueva pestaña
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');


}
